import React from 'react'
import classNames from 'classnames'
import Layout from 'layout/layout'
import Typist from 'react-typist'
import { graphql } from "gatsby"
import { Link } from 'gatsby'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Parallax from 'components/Parallax/Parallax.js'
import landingPageStyle from 'assets/jss/styles/pages/landingPageStyle.js'
import { makeStyles } from '@material-ui/core/styles'
import SectionConstructionMethods from 'pages-sections/construction/SectionConstructionMethods'
import SectionServicesConstruction from 'pages-sections/construction/SectionServicesConstruction'
import SectionNeedLand from 'pages-sections/construction/SectionNeedLand'

const useStyles = makeStyles(landingPageStyle);

const pageTitle = "Casas prefabricadas con contenedores marítimos";
const pageDescription = "Construimos tu casa con contenedores marítimos, casas de gran calidad, resistentes, modulares, ecológicas y originales. Casas prefabricadas tanto para camping o fin de semana, como tu vivienda habitual. Pregúntanos y te haremos un presupuesto personalizado.";

const texts = [
  "de altas prestaciones",
  "sostenibles",
  "personalizadas",
  "económicas",
]

const IndexPage = ({ data }) => {
  const [renderMsg, setRenderMsg] = React.useState(true);
  const classes = useStyles()
  const delay = 1500;

  const reactive = () => {
    setTimeout(() => {
      setRenderMsg(true);
    }, 1000)
  }

  const onHeaderTyped = () => {
    reactive();
    setRenderMsg(false);
  }

  return (
    <Layout
      pageTitle={pageTitle}
      pageDescription={pageDescription}
      headerImageGatsby={data.construccionJson.headerImage.childImageSharp.fluid}
      bgImgStyle={{backgroundPositionY: "top"}}
      filter="dark"
      content={
        <React.Fragment>
          {renderMsg ? (
            <h2 className={classes.typist}>
              <span>Construimos casas </span>          
              <Typist
                className="typist-header"
                avgTypingDelay={20}
                startDelay={1000}
                onTypingDone={onHeaderTyped}
              > 
                {texts.map(t => (
                  <span>                  
                    <span>{t}</span>
                    <Typist.Backspace count={t.length} delay={delay} />
                  </span>
                ))}
              </Typist>
            </h2>
          ) : (
            <h2 className={classes.typist}>|</h2>
          )}

          <h4 className={classes.subtitle}>A un precio sin compentencia</h4>

          <Link to="/presupuesto">
            <Button color={'primary'} className={classes.navButton}>
              Solicita Presupuesto
            </Button>
          </Link>

          <br />
        </React.Fragment>
      }
    >
      <SectionServicesConstruction />
      <SectionConstructionMethods classes={classes} />
      <SectionNeedLand />
    </Layout>
  )  
}

export default IndexPage

export const query = graphql`
  query ConstruccionpageQuery {
    site {
      id
      siteMetadata {
        siteTitle
        siteDescription
      }
    }
    construccionJson {
      title
      subtitle
      headerImage {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;