import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Gesture from "@material-ui/icons/Gesture";
import Build from "@material-ui/icons/Build";
import BorderColorIcon from '@material-ui/icons/BorderColor';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import servicesStyle from "assets/jss/styles/pages/landing/servicesStyle.js";

const useStyles = makeStyles(servicesStyle);

export default function SectionServicesConstruction() {
  const classes = useStyles();
  return (
    <div className={classes.services}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
        >
          <h1 className={classes.title}>
            Casas prefabricadas con contenedores marítimos
          </h1>
          <h6>
            <b>Construimos la casa de tus sueños</b>
          </h6>
          <h2 className={classes.description}>
            Las <b>casas prefabricadas con contenedores marítimos</b> ofrece grandes ventajas. ¿Cómo disfrutar de una casa de altas prestaciones a coste reducido?
            La <b>arquitectura modular</b> permite realizar el diseño a tu medida. Con plazos y precios fijos, ¡sin sorpresas!. Viviendas diseñadas para ti, de gran calidad y en poco tiempo.
          </h2>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem md={4} sm={4}>
          <InfoArea
            title="1. Diseño"
            description={
              <span>
                <p>
                  Diseñamos tu <b>casa modular a medida</b>. En esta sección encontrarás ejemplos de distribución posibles, <b>¡pero los límites los pones tú!</b>
                </p>
              </span>
            }
            icon={Gesture}
            iconColor="primary"
          />
        </GridItem>
        <GridItem md={4} sm={4}>
          <InfoArea
            title="2. Calidad-precio"
            description={
              <span>
                <p>
                  <b>Casas baratas</b> sin renunciar a la <b>calidad</b> y el <b>confort</b>. Cumplimos plazos y presupuesto. Disponemos de un amplio catálogo de materiales de distintas calidades / precio para que puedas elegir la que más te convenga.{" "}
                </p>
              </span>
            }
            icon={Build}
            iconColor="primary"
          />
        </GridItem>
        <GridItem md={4} sm={4}>
          <InfoArea
            title="3. Cumplimos la normativa"
            description={
              <span>
                <p>
                  Nuestras viviendas están diseñadas cumpliendo las normativas del CTE.
                </p>
              </span>
            }
            icon={BorderColorIcon}
            iconColor="primary"
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
