import React from 'react'
import { Link } from 'gatsby'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import Button from 'components/CustomButtons/Button.js'

import Container from "assets/img/sections/container.png";
import { CONTENEDORES } from 'constants/routes';

const CardLayout = ({ classes, href, title, desc, img }) => {
  return (
    <GridItem xs={12} /*md={6} lg={6}*/>
      <Card background style={{ backgroundImage: `url(${img})` }}>
        <Link to={href}>
          <CardBody background>
            <h3 className={classes.cardTitleWhite}>{title}</h3>
            <p className={classes.cardDescriptionWhite}>{desc}</p>
            <Button round color="danger">
              Ver más
            </Button>
          </CardBody>
        </Link>
      </Card>
    </GridItem>
  )
}

const SectionConstructionMethods = ({ classes }) => {
  return (
    <div className={classes.wrapper}>
      <GridContainer>

        <CardLayout 
          classes={classes}
          img={Container}
          href={CONTENEDORES}
          title="Construcción con contenedores marítimos"
          desc='Construcción modular con contenedores marítimos. Entra para ver el catálogo.'
        />       
      </GridContainer>
    </div>
  )
}

export default SectionConstructionMethods
