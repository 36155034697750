import {
  title,
  description,
  mrAuto,
  mlAuto
} from "assets/jss/styles.js";

const servicesStyle = {
  title: {
    ...title,
    fontSize: "2.5rem"
  },
  mrAuto,
  mlAuto,
  services: {
    paddingTop: "10px",
    paddingBottom: "80px"
  },
  textCenter: {
    textAlign: "center"
  },
  description: {
    ...description,
    fontSize: "1.0625rem"
  }
};

export default servicesStyle;
